import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

export function selectBurgerprofielHeader(state) {
  return get(state, [
    "burgerprofielConfiguration",
    "configuration",
    "headerEmbedCode",
  ]);
}

export function selectBurgerprofielFooter(state) {
  return get(state, [
    "burgerprofielConfiguration",
    "configuration",
    "footerEmbedCode",
  ]);
}

export function hasLoadedBurgerprofiel(state) {
  return get(state, ["burgerprofielConfiguration", "loaded"]);
}

export function hasValidBurgerprofielFooter(state) {
  return !isEmpty(
    get(state, [
      "burgerprofielConfiguration",
      "configuration",
      "footerEmbedCode",
    ])
  );
}

export function hasValidBurgerprofielHeader(state) {
  return !isEmpty(
    get(state, [
      "burgerprofielConfiguration",
      "configuration",
      "headerEmbedCode",
    ])
  );
}

export function isAbleToCreateHoppinPuntenDossiers(state) {
  return get(
    state,
    ["dossierCreationToggles", "ableToCreateHoppinPuntenDossiers"],
    false
  );
}

export function isAbleToCreateToegangkelijkeHaltesDossiers(state) {
  return get(
    state,
    ["dossierCreationToggles", "ableToCreateToegangkelijkeHaltesDossiers"],
    false
  );
}

export function isAbleToCreateVeiligeSchoolOmgevingenDossiers(state) {
  return get(
    state,
    ["dossierCreationToggles", "ableToCreateVeiligeSchoolOmgevingenDossiers"],
    false
  );
}

export function isAbleToCreateVervoerOpMaatDossiers(state) {
  return get(
    state,
    ["dossierCreationToggles", "ableToCreateVervoerOpMaatDossiers"],
    false
  );
}

export function isAbleToCreateZeroEmissieDossiers(state) {
  return get(
    state,
    ["dossierCreationToggles", "ableToCreateZeroEmissieDossiers"],
    false
  );
}
